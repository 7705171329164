import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const IndexPage = ({ pageContext: {solutions} }) => (
  <Layout>
    <h2>индекс</h2>
    <ol>
      {solutions.map((sol, i) =>
      <li>
        <Link to={`/${i+1}`}>{sol.title}</Link>
      </li>
      )}
    </ol>
  </Layout>
)

export default IndexPage
